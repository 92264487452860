import React from 'react';
import MenuIcon from '@material-ui/icons/MenuSharp';
import CloseIcon from '@material-ui/icons/CloseSharp';
let menuTimeout;
export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            menuButton: true

        };

    }

    menuClick = () => {
        //  alert('he')



        this.setState({
            visible: true,
            menuButton: false

        })
    }
    closeClick = () => {
        //  alert('he')
        this.setState({
            visible: false,
            menuButton: true

        })
    }
    mouseLeaveHeader = () => {
        const t = this;


        if (!this.state.menuButton) {
           // console.log('OUT')
            menuTimeout = setTimeout(function () {
                t.setState({
                    visible: false,
                    menuButton: true
                })

            }, 2000)
        }


    }
    mouseEnterHeader = () => {

        // const t = this;

        if (!this.state.menuButton) {
           // console.log('IN')
          //  console.log('Cleared')
            clearTimeout(menuTimeout);
            return;

        }

    }
    click = (e, route) => {
        this.setState({
            visible: false,
            menuButton: true

        })
        this.props.click(e, route)
    }
    componentDidMount() {


    }
    render() {

        return (
            <div className="App-Header" >
                <div className="App-Header-Left" onMouseEnter={e => this.mouseEnterHeader()} onMouseLeave={e => this.mouseLeaveHeader()}>


                    <MenuIcon style={{ color: '#666' }} className={this.state.menuButton ? "Menu-Icon-fadeIn" : "Menu-Icon-fadeOut"} fontSize={"small"} onClick={() => this.menuClick()} />


                    <CloseIcon style={{ color: '#555' }} className={this.state.menuButton ? "Close-Icon-fadeOut" : "Close-Icon-fadeIn"} fontSize={"small"} onClick={() => this.closeClick()} />


                    <div className={!this.state.menuButton ? "menu-mobile": "menu"}>
                        <button disabled={this.state.menuButton} onClick={e => this.click(e, '/')} className={this.state.visible ? "Menu-Item-1-fadeIn" : "Menu-Item-1"}>resume</button>
                        <button disabled={this.state.menuButton} onClick={e => this.click(e, '/about')} className={this.state.visible ? "Menu-Item-2-fadeIn" : "Menu-Item-2"}>about</button>
                        <button disabled={this.state.menuButton} onClick={e => this.click(e, '/contact')} className={this.state.visible ? "Menu-Item-3-fadeIn" : "Menu-Item-3"}>contact</button>
                        {/* <span className={this.state.visible ? "Menu-Item-4-fadeIn" : "Menu-Item-4"}>contact</span> */}
                    </div>
                </div>
                <div className="App-Header-Right">
                    <span style={{ cursor: 'pointer', letterSpacing: 0 }} onClick={e => this.click(e, '/')}><span>{'scott'}</span><span style={{marginLeft:2.5}}>{'salisbury'}</span><span style={{marginLeft:2.5}}>{'dev'}</span></span>
                    {/* <img style={{width:150, opacity:0.8}} src={"/logo.png"}/> */}
                </div>



            </div>

        );

    }


}