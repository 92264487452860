import React from 'react';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {


        };

    }


    componentDidMount() {


    }
    render() {

        return (
            <div className="App-Footer" >

                scottsalisburydev.com &copy; 2024

            </div>

        );

    }


}