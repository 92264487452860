import React from 'react';
import Footer from './Footer'
import Header from './Header'
import Me from '../images/some_guy.png'; // with import


export default class Body extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            route: window.location.pathname,
            articleClick: false
        };

        this.myRef = React.createRef();


    }
    getData = (rr) => {

        const t = this;
        fetch('./data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                ////console.log(response)
                return response.json();
            })
            .then(function (data) {
                window.scrollTo(0, 0);
                let route = window.location.pathname
                let pages = data[0].pages;
                for (let r = 0; r < pages.length; r++) {
                    // //console.log(pages[r].route);

                    if (pages[r].route === route) {
                        // //console.log(data[0].pages[r])
                        t.setState({
                            page: pages[r],
                            height: window.innerHeight,
                            route: rr

                        });
                        return;
                    } else {
                        t.setState({
                            page: pages[3],
                            height: window.innerHeight,
                            route: '/404'

                        })
                    }


                }


            });

    }
    getHeight = () => {

        return window.innerHeight

    }
    click = (e, data) => {
        window.history.pushState("", "", data);
        //  this.setState({ route: data, articleClick: false });
        this.getData(data);

    }

    resize() {
        this.setState({ height: window.innerHeight });
    }
    componentDidMount() {
        window.addEventListener('locationchange', this.locationChange);
        window.addEventListener("resize", this.resize.bind(this));
        // this.resize();
        this.getData();

    }
    componentWillUnmount() {
        window.removeEventListener('locationchange', this.locationChange);

        window.removeEventListener("resize", this.resize.bind(this));
    }
    render() {

        return (
            <>
                <Header click={this.click} />

                <div className="Main-Body" >

                    {this.state.page ? this.getContent(this.state.page) : null}
                    {this.state.page ? <Footer /> : null}



                </div>
            </>
        );

    }

    getContent = (page) => {
        //console.log(page)
        let layout;

        switch (page.layout) {
            case 'landing':
                layout = this.renderLanding(page);
                break;
            case 'content':
                layout = this.renderContent(page);
                break;
            case 'content-img':
                layout = this.renderContentImg(page);
                break;
            case 'list':

                layout = this.renderList(page);
                break;
            case 'article':
                layout = this.renderArticle(page);
                break;

            default:
                layout = this.renderLanding(page);
                break;
        }

        return layout;

    }
    renderLanding = (page) => {
        return (
            <>
                {/* <div style={{ textAlign: 'center', marginBottom: 40, height: 20, transform: 'rotate(180deg)' }}>&#9734;</div> */}
                <div className={"stars"}>

                    <div>&#9734;</div>

                </div>
                {/* <div style={{ marginLeft: 0, width:85, margin:'60px auto 100px auto', textAlign:'center', textAlign: "left", fontSize: "17px", fontWeight: "regular" }}>
                    {"Resume"}
                </div> */}
                <ul key={page.title} ref={this.myRef} className="Main-List" >

                    {page.content.map((data, index) => {
                        return (
                            <li key={index}
                            // onClick={e => this.click(e, data.route)}
                            >
                                <div style={{ marginLeft: 0, textAlign: "left", fontSize: "27px", fontWeight: "regular" }}>
                                    {data.title}
                                </div>
                                <div style={{ fontStyle: "italic", marginLeft: 2, marginTop: "10px", textAlign: "left", fontSize: "15px", fontWeight: "regular" }}>
                                    {data.date}
                                </div>
                                <div style={{ marginLeft: 2, marginTop: "16px", textAlign: "left", fontSize: "20px", lineHeight: 1.2, fontWeight: "regular" }}>
                                    {data.preview}
                                </div>

                            </li>
                        )

                    })}


                </ul>
                {/* <div className={'see-more'} onClick={e => this.click(e, 'writings')}>see more</div> */}
                <div style={{ textAlign: 'center', margin: '40px 0' }}>&#9734;</div>



            </>
        )
    }
    renderContent = (page) => {
        return (
            <>
                <div className={"stars"}>

                    <div>&#9733;</div>

                </div>
                <div key={page.title} className="center" style={{ minHeight: this.state.height - 70 }}>
                    {/* <p style={{ fontSize: 24, marginBottom:0 }}>{page.content.title}</p> */}
                    <div style={{ width: 200 }}>
                        <p dangerouslySetInnerHTML={{ __html: page.content.content }} style={{ lineHeight: 1.5, fontSize: 20, marginTop: -150, textAlign: 'center', width: "100%" }}></p>

                    </div>

                </div>

            </>
        )
    }
    renderContentImg = (page) => {
        return (
            <>
                <div className={"stars2"}>

                    <div>&#9733;</div>

                </div>
                <div key={page.title} style={{ minHeight: this.state.height }}>
                    <div style={{ margin: '0 auto', width: 250, height: 250, marginBottom: 30 }}> <img src={Me} alt={'Some Guy'} style={{ objectFit: 'cover', height: 250, width: 250, opacity: 1, borderRadius: 360, border: "1px solid rgba(0, 0, 0, .6)", }} /></div>
                    <div style={{ margin: '0 auto', width: '100%', maxWidth: 600, minWidth: 350 }}>
                        <p dangerouslySetInnerHTML={{ __html: page.content.content }} style={{ lineHeight: 1.5, fontSize: 20, textAlign: 'left' }}></p>
                    </div>
                    <div style={{ fontSize: 16, margin: '50px auto 0 auto', width: 250 }}>
                    </div>
                </div>


            </>
        )
    }
    renderList = (page) => {
        return (
            <>
                <div style={{ textAlign: 'center', marginBottom: 40, height: 20 }}>&#8224;</div>
                <ul key={page.title} ref={this.myRef} className="Main-List" >

                    {page.content.map((data, index) => {
                        //console.log(data)
                        return (
                            <li key={index}
                            // onClick={e => this.click(e, data.route)}
                            >
                                <div style={{ textAlign: "left", fontSize: "26px", fontWeight: "regular" }}>
                                    {data.title}

                                </div>
                                <div style={{ marginLeft: 2, marginTop: "14px", textAlign: "left", fontSize: "14px", color: '#111', fontWeight: "regular" }}>
                                    {data.date}
                                </div>
                                <div style={{ marginLeft: 2, marginTop: "10px", textAlign: "left", fontSize: "20px", lineHeight: 1.3, fontWeight: "regular" }}>
                                    {data.preview}
                                </div>

                            </li>
                        )

                    })}


                </ul>
                <div style={{ textAlign: 'center', margin: '0px 0 50px 0', transform: 'rotate(180deg)' }}>&#8224;</div>

            </>
        )
    }
    renderArticle = (page) => {
        //console.log(page)
        var first = page.content.charAt(0)
        var sub = page.content.substring(1);
        //console.log(first)
        //console.log(sub)

        return (
            <div style={{ minHeight: this.state.height }}>
                <div className={"stars"}>

                    <div>&#9733;</div>

                </div>

                <div className={'article'}>
                    <div style={{ fontSize: 40, textAlign: 'center' }}>{page.title}</div>

                    {/* <div style={{ textAlign: 'center', fontSize: 16, color:"#380000" }}>{page.author} </div> */}
                    <div style={{ textAlign: 'center', fontSize: 14, margin: '15px 0 40px 0' }}>{page.date}</div>

                    <div style={{ fontSize: 22, margin: '0 auto 30px auto' }}>
                        <span style={{ position: 'relative', color: "#380000", top: 0, fontSize: 40 }}><i>{first}</i></span>
                        <span style={{ lineHeight: 1.5 }}>{sub}</span>
                    </div>
                </div>
            </div>
        )
    }

    locationChange = (e) => {
        const route = window.location.pathname


        this.getData(route);



    }
}

window.history.pushState = (f => function pushState() {
    var ret = f.apply(this, arguments);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
})(window.history.pushState);

window.history.replaceState = (f => function replaceState() {
    var ret = f.apply(this, arguments);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
})(window.history.replaceState);

window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'))
});