import './App.css';
import Body from './App/Body'

function App() {
  return (
    <div className="App">
      <Body />
    </div >
  );
}

export default App;
